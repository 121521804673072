/* Responsive css start here ------------------ */

@media screen and (min-width: 360px) {
  .forgot-form p {
    margin-bottom: 32px;
  }
  .forgot-form.set-password-form p {
    margin-bottom: 20px;
  }
  .custom-table .action-btn .tool-btn {
    margin: 0 2px;
  }
  .custom-table .custom-col-1 {
    max-width: 36%;
    flex: 0 0 36%;
    padding: 0px 0;
  }
  /* .divbody >div:nth-last-child(4) .light-tooltip::after {
  bottom: 6px;
  left: 159px;
  margin-left: 0;
  border-left-color: #ffffff;
  border-bottom-color: transparent;
} */
  /* .divbody >div:first-child .light-tooltip::after{
  top: 3px;
  left: 159px;
  margin-left: 0;
  border-left-color: #ffffff;
  border-bottom-color: transparent;
} */
  /* .divbody >div .light-tooltip
 {
  top: -76px;
  left: -138px;
} */
  /* .divbody >div:first-child .light-tooltip {
  top: -15px;
  left: -138px;
} */

  .add-new-table {
    font-size: 12px;
  }
  .overlay-tooltip::after {
    left: 128px;
  }
  .light-tooltip::after {
    left: 50px;
  }
  .custom-table.hourly-table .bx-col {
    min-width: 100%;
  }
  .custom-table.hourly-table .bx-col .price-col {
    width: 70px;
  }
  .add-club-wrapper .accordion {
    margin: 0;
  }
  .tournamentCreateButtons .form-group,
  .tournamentCreateButtons .red-btn {
    margin-right: 0;
  }
  .tournamentCreateHeading {
    padding: 0 15px;
  }
  .add-club-wrapper {
    margin: 0;
    padding: 0px 10px;
  }

  .myTransactionsearch .playerMemberSelectBox {
    width: 100%;
  }

  .datePickerWidth .react-datepicker-wrapper input[type="text"] {
    padding: 10px 5px;
  }
  .add-club {
    width: auto;
  }
  .add-club.MemberTransactionsfilter {
    padding: 7px 17px;
  }
  .layout-view h4 {
    font-size: 14px;
    line-height: 2;
  }
  .plus-icon-player.add-icon {
    left: unset;
    right: 10px;
    top: 10px;
    z-index: 2;
  }
  .main-container {
    padding: 95px 10px 24px 10px;
  }
  .tournament-btn {
    top: 47px !important;
    right: 110px !important;
  }
  .Matches-Schedule-colorCodeText {
    margin-top: 50px;
  }
  .logo {
    max-width: 80px;
  }
  .paymentMemberTable th {
    font-size: 14px;
    line-height: 16px;
    vertical-align: top !important;
  }
  .reviewpayment-footer .cancel-btn,
  .reviewpayment-footer .red-btn {
    width: 100%;
  }
  #userDetailsPopup .modal-dialog {
    max-width: 340px;
    margin: 10px auto;
  }
  .add-table-section .btn-col .btn-gray,
  .add-table-section .btn-red-outline {
    margin-right: 0;
  }
  .transaction {
    flex: 0 0 249px !important;
  }
}

@media screen and (min-width: 576px) {
  .sidebar {
    max-width: 250px;
  }

  /*.overlayout {
    left: 0;
    right: 0;
    bottom: 20px;
    padding: 0 20px;
  }*/
  .playerDetails {
    overflow: hidden;
  }
  .number-score{
    width: 107px;
    position: relative;
    padding: 6px 21px 6px 21px;
    margin-left: 0px;
    background: #124e25;
    clip-path: polygon(11% 0%, 100% 0%, 89% 100%, 0% 100%);
  }
  .number-score::before {
    content: "";
    position: absolute;
    left: 0;
    top: -24px;
    width: 2px;
    height: 108px;
    background-color: #666666;display: none;
    transform: rotate3d(1, 1, 1, 50deg);
  }
  .number-score::after {
    content: "";
    position: absolute;
    right: 28px;display: none;
    top: -24px;
    width: 2px;
    height: 108px;
    background-color: #666666;
    transform: rotate3d(1, 1, 1, 50deg);
  }
  .viewer {
    bottom: 100px;
  }
  .custom-table .action-btn .tool-btn {
    margin: 0 6px;
  }
  .add-new-table {
    font-size: 14px;
  }
  .overlay-tooltip::after {
    left: 112px;
  }

  .light-tooltip::after {
    left: 10px;
  }
  .tool-tip-arrow.signout-right-tooltip::after {
    margin-left: 30px;
  }
  .layout-view h4 {
    font-size: 18px;
    line-height: 21px;
  }

  .tournament-btn {
    top: 19px !important;
    right: 16px !important;
  }
  .Matches-Schedule-colorCodeText {
    margin-top: 0;
  }
}

@media only screen and (min-width: 467px),
screen and (min-device-width: 467px) {
  .scrores {
    background-color: #fff;
    /* width: 190px; */
    min-width: 190px;
  }
  .overlayout .century {
    width: 190px;
    min-width: 190px;
  } 
  .overlayout .breaks {
    width: 34.3%;
  }
  .overlayout .breaks.ahead {
    width: 34.3%;
  }
}
@media only screen and (min-width: 820px),
screen and (min-device-width: 820px) {
  .scrores {
    background-color: #fff;
    width: 255px;
    min-width: 255px;
  }
  .overlayout .century{
    width: 255px;
    min-width: 255px;
  }
}

@media only screen and (max-width: 466px),
  screen and (max-device-width: 466px) {
    .overLayScore {
      font-size: 12px;    word-break: break-word;
      line-height: 1;

  }
  .scrores {
    background-color: #fff;
    width: 160px;
    min-width: 160px;
  }
  .overlayout .century {
    width: 160px;
    min-width: 160px;
  }
  .overlayout .possible h5 {
    padding: 0 2px;
}
.overlayout .breaks.right h4 {
  padding-right: 4px;
}
.overlayout .breaks.right {
  text-align: right;
}
.overLayScore.left-text {
  text-align: left;
}
.overLayScore.right-text {
  text-align: right;
}
  .live-btn a span {
    margin-right: 3px;
}
.live-btn a e {
  display: none;
}

  .overlayout .breaks {
    height: fit-content;
}
.center-col {
  background: #fff;
}
  .overlayout .century {
    padding: 2px 0px;
    height: fit-content;
    width: 51.6%;
}
.overlayout .possible h5 span {
  width: 17px;
  height: 17px;
  line-height: 17px;
  margin: 1px 1px;
}
.possible .d-flex.flex-md-row.align-items-center.justify-content-between {
  align-items: unset !important;
}
  .inner-container .match-status iframe {
    height: auto !important;
    max-height: initial;
}
.overlayout .breaks {
  width: 42.3%;

}
  .scrores {
    width: 44.3%;
    height: 31px;
}
.playerScoreOne, .playerScoreTwo{width:53px;}
  .playerScores{font-size: 13px;}
  .live-scoring .white-col .inner-container {
    padding: 22px 0px;min-height:unset;
}
.playerDetails .overLayScore.left-text, .overLayScore.right-text {
  width: 28%;
  padding: 3px 3px;
}
.overlayout .breaks.right h5 {
  right: 0px;
}
.strikeCircle {
  margin: 1px 3px;
  position: unset;
   transform:unset;    display: block;

}
.playerDetails .overLayScore.left-text>div, .overLayScore.right-text>div {
  display: flex;
  justify-content: center;
}
  .newsCardMember {
    max-width: 182px;
}
  .manage-player-tab.newss {
    margin-top: 3em;
}
.newsPageNumber {
  top: -57px;
}
.newsSearch {
  top: -99px;
  position: absolute;
  right: 0px;
}
  .newsCardMember .text-truncate {
    max-width: 179px !important;
}
  .managenews .table {
    width: 111.4% !important;
}
  .add-club{margin: 0;
  }
  .copy .checkmark {
    width: 38px;
  }
  .upcomingtour.manage-player-tab-wrapper > div {
    width: 100%;    margin: 5px 0px 5px 0px !important;
}
.upcomingtourna .d-md-flex.left .noRecord {
  margin: 0px 0px;
}
.upcomingtourna .success-alert {
  font-size: 13px;;
}
.upcomingtourna .box {
  margin: 13px 0px 13px 0px;
}
.upcomingtourna .d-md-flex.left {
  margin: 0px;
}
.upcomingtourna .box .btn {
  display: flex;    bottom:10px;
}
.content-wrapper {
  margin: 0px 15px;
}

  .manage-player-tab-wrapper.mybooking .manage-player-tab.nav-tabs .nav-link {
    font-size: 16px !important;
    padding: 12px 40px !important;
    min-width: inherit;
}


  .center-col {
    order: 1;
  }
  .number-score::before {
    left: 15px;
    height: 79px;
  }
  .number-score .overLayScore {
    position: relative;
    top: 0px;
    left: -2px;
  }

  .number-score::after {
    height: 79px;
    right: 13px;
  }
  .overLayScore.right-text {
    order: 3;
  }
  .add-new-table {
    font-size: 12px;
  }

  /* .light-tooltip::after {
    left: 37px;
} */
}
@media only screen and (max-width: 393px),
  screen and (max-device-width: 393px) {
    .playerScoreOne, .playerScoreTwo {
      width: 50px;
  }
  }

@media only screen and (max-width: 360px),
  screen and (max-device-width: 360px) {
    .playerScoreOne, .playerScoreTwo{width:40px;}

  }
@media only screen and (max-width: 767px),
  screen and (max-device-width: 767px) {
  .edit-food-content {
    flex-direction: column;
  }
  

  .search-section {
    padding: 0px 15px;
}
  .edit-food .upload-image figure {
    max-width: 286px;
    margin: 0 auto;
  }
  .edit-food .item-content {
    max-width: 100%;
    margin: 0;
    margin-top: 15px;
  }
  .edit-food .modal-footer {
    margin-top: 20px;
  }
  .left-bottom-text {
    text-align: left;
  }

  .inner-container .match-status iframe {
    height: 50px;
  }

  .inner-container .match-status {
    max-width: 460px;
  }

  /* .player-tooltip{
    margin-top: -5px;
    margin-left: 0;
  } */
  .player-tooltip.large {
    height: 440px;
    max-width: 320px;
  }
  .table td a:hover ~ .player-tooltip,
  .table td a:hover .player-tooltip {
    padding: 20px;
    padding-top: 5px;
  }
  .player-tooltip svg.bottom {
    width: 93%;
    height: auto;
  }
  .player-tooltip .left-col {
    box-shadow: 0px 4px 8px rgba(18, 78, 37, 0.3);
    border-radius: 20px;
    background: #fff;
  }
  .player-tooltip .right-col {
    box-shadow: 0px 4px 8px rgba(181, 40, 48, 0.3);
    border-radius: 20px;
    background: #fff;
    justify-content: flex-start !important;
  }
  .player-tooltip .or-col {
    padding: 12px 0;
  }
  .player-tooltip .title-top {
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .player-tooltip .right-col svg.bottom {
    transform: rotate3d(0, 180, 0, 182deg);
  }
  .player-tooltip .left-col .inside-content {
    padding-left: 20px;
  }
  .player-tooltip .right-col .right-content {
    padding-left: 15px;
    padding-right: initial;
    order: 2;
  }
  .player-tooltip .right-col .inside-content figure {
    order: 1;
  }
  .player-tooltip.large .right-col .right-content {
    align-items: flex-start;
  }
  .player-tooltip .right-col .country-name {
    justify-content: flex-start !important;
  }
  .player-tooltip .right-col .right-content .title,
  .player-tooltip .right-col .bottom-txt {
    text-align: left;
  }
 
  .results-modal.results-height .table-container {
    max-height: auto;
    overflow-y: hidden;
}
  .player-info .right-player .info-right {
    margin-left: 0;
  }
  .player-info .left-player .info-left {
    margin-right: 0;
  }
  
  .player-info .right-player {
    margin-left: initial;
  }
  .player-info .vs-col {
    position: relative;
    transform: initial;
    max-width: 100%;
    width: 100%;
    padding: 25px 0;
    left: inherit;
    top: inherit;
  }
   .player-info {
    border: none;
  }
  .player-info .vs-col:after {
    content: "";
    height: 1px;
    width: 100%;
    background: #c7d5cb;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .results-modal-container {
    width: 100%;
    top: 74%;

  }
  .player-info .vs-col span {
    position: relative;
    z-index: 2;
    background: #fff;
    padding: 0 5px;
  }
  .player-info .left-player,
  .player-info .right-player {
    max-width: 100%;
    flex: 0 0 100%;
    align-items: flex-start !important;
  }
  .player-info .left-player .content-left {
    margin-left: 15px;
  }
  .player-info .left-player .content-left,
  .player-info .right-player .content-left {
    align-items: flex-start !important;
    order: 2;
  }
  .player-info figure {
    order: 1;
  }
  .signout-right-tooltip::after {
    margin-left: 30px;
  }
  /* .overlay-tooltip::after {
    left: 82px;
} */

  .column-wrapper .child {
    max-width: calc(100%);
    flex: 0 0 calc(100%);
  }
}
@media only screen and (min-width: 1399px) and (max-width: 1900px) {

/*div#tournamentSchedule .tournament-list, #knockoutSchedule .tournament-list {
  overflow-x: scroll;
}*/
.tool-tip-arrow-score::after {
  left: 0 !important;
}
}
@media only screen and (min-width:600px) and (max-width: 1700px) {

div#tournamentSchedule .tournament-list, #knockoutSchedule .tournament-list {
  overflow-x: scroll;
}
}
@media only screen and (max-width: 600px) {
div#knockoutSchedule .nav.nav-tabs{
    width: auto !important;
}
.add-club-management .organization-information {
  width: 100%;
}
.create-tournament-red-btn {
  font-size: 13px;
}
  div#tournamentSchedule .tournament-list, #knockoutSchedule .tournament-list {
    overflow-x: scroll;
  }
  }
@media only screen and (min-width: 768px) and (max-width: 850px) {
  .player-tooltip.large {
    max-width: 586px;
    top: -185px;
  }
  .whens.knockout-whens {
    margin-top: 43px;
   }
  .player-tooltip svg.bottom {
    width: 100%;
    height: auto;
  }
  .player-tooltip .or-col {
    padding: 0 10px;
  }
  .player-tooltip {
    top: 20px;
  }

  .column-wrapper .child {
    max-width: calc(100%);
    flex: 0 0 calc(100%);
  }

  .Matches-Schedule-colorCodeText {
    margin-top:0px;
  }
}
@media only screen and (min-width: 1710px) and (max-width: 1900px) {
  .generate-list-item-mobile-screen{
  display: none !important;
  }
}
@media (max-width:1299px) {
  .arrows.up {
    right: 22px;
}
.wrap.transactionloader{overflow: auto !important;}

.newsCardMember .text-truncate {
  max-width: 541px;
}
.arrows.down {
  right: 22px;
}
.managenews .club-name-bold span {
  width: 599px;
}
}

@media (max-width:1024px) {
.manage-player-tab-wrapper.mybooking .manage-player-tab.nav-tabs .nav-link{
  font-size: 14px;
  padding: 12px 19px;
  min-width: inherit;
}
.arrows.up {
  right: 0px;
}
.arrows.down {
right: 0px;
}
.upcomingtourna .box {
  flex-basis: calc(33.3% - 12px);}
  .managenews .club-name-bold span {
    width: 453px;
}
}
@media (max-width:991px) {
  .arrows.up {
    right: -7px;
}
.arrows.down {
  right: -7px;
}
.managenews .club-name-bold span {
  width: 225px;
}
}
@media (max-width:768px) {
.manage-player-tab-wrapper.mybooking #myTab {
  margin-top: 26px;
}
.manage-player-tab-wrapper.mybooking {
  display: unset !important;
}
}
@media (min-width: 768px) {
  .playerDetails {
    flex-direction: row !important;
  }
  .login-page.enters .error-alert {
    top: 3%;
}
  .login-page {
    padding: 48px 32px;
  }
  .login-page.enterlogin .popup-close-btn {
    right: 13px;
    top: 11px;
}
  .login-page.enterlogin{
    padding: 38px 32px;
  }
  .forgot-password {
    font-size: 15px;
    line-height: 18px;
  }
  .login-form label.form-check-label {
    font-size: 14px;
    line-height: 16px;
  }
  .forgot-password-wrapper .login-page {
    padding: 24px 32px 27px;
  }
  .main-container {
    padding: 73px 0 0 250px;
  }
  .my-account-wrapper,
  .content-wrapper {
    padding: 24px;
  }
  
  .content-wrapper.wrappers {
    padding-top: 36px;
}
  .header {
    padding: 0 24px 0 0;
  }
  .main-logo {
    max-width: 250px;
    text-align: left;
  }
  .add-club {
    margin: 0;
    width: auto;
  }
  .forgot-password-wrapper .set-password-form .login-btn {
    margin: 16px 0 13px;
  }
  .set-password-logo {
    max-width: 140px;
  }
  .login-btn {
    font-size: 20px;
    line-height: 24px;
  }
  .forgot-form.set-password-form h3 {
    font-size: 24px;
    line-height: 28px;
  }
  .set-password-form h3 svg {
    width: 18px;
    height: 24px;
  }
  .change-password-form,
  .personal-info {
    width: 100%;
    padding: 32px;
    border: 1px solid #cccccc;
    border-radius: 8px;
    margin: 0 auto;
  }
  .sidebar-details-inner {
    padding: 32px;
  }
  .sidebar {
    overflow-y: hidden;
  }
  .upload-logo-view {
    height: 126px;
  }
  .add-club-wrapper {
    padding: 24px;
  }
  .add-player-wrapper {
    padding: 24px;
  }
  .main-container {
    height: 100%;
  }
  .register-player .add-player-wrapper {
    padding: 0;
  }
  .add-club-wrapper .accordion {
    margin: 0;
  }
  .register-as-player .add-player-wrapper {
    padding: 48px 32px;
  }
  .manage-player-tab.nav-tabs .nav-link {
    font-size: 16px;
    line-height: 19px;
    padding: 12px 40px;
  }
  .list-of-type li:first-child {
    padding-left: 0;
  }
  .list-of-type li:first-child::before {
    display: none;
  }
  .match-card-item {
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    padding: 15px;
  }
  .list-of-type li {
    padding-left: 14px;
  }
  .list-of-type li::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 7px;
    height: 4px;
    width: 4px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 100%;
  }
  .red-icon {
    top: 42%;
    bottom: initial;
  }
  .name-wrapper {
    padding: 7px 15px;
  }
  .left-side {
    left: 16px;
  }
  .right-side {
    right: 16px;
  }
  .player-1,
  .player-2 {
    position: static;
    text-align: center;
    width: 30%;
  }
  .social-share-link {
    position: static;
  }
  .number-count {
    width: 40%;
  }
  .with-social-icon {
    flex: 0 0 calc(100% - 230px);
    max-width: calc(100% - 230px);
  }
  .only-one-social-icon {
    flex: 0 0 calc(100% - 133px);
    max-width: calc(100% - 133px);
  }
  #registerAsPlayer .register-as-player {
    padding: 0;
  }
  #registerAsPlayer .d-table-cell {
    padding: 0 15px;
  }
  .playerDetails {
    padding: 0px 25px;
  }
  
  .allTransaSelcletBox {
    height: 37px;
    flex: 1 1 calc(50% - 5px);
    max-width: calc(50% - 5px);
  }
 
  .transaction {
    flex: 0 0 250px !important;
  }
  .sticky-layout {
    position: fixed;
    right: 24px;
    left: 274px;
    top: 73px;
    z-index: 10;
    animation: .3s slideInDown ease-in-out;
    -webkit-animation: .3s slideInDown linear;
  }
  @keyframes slideInDown {
      0% {
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0);
    visibility: visible;
}
100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}  
}

  .sticky-layout .white{background: #fff;}
  .sticky-layout ~ .wrap {
    padding-top: 178px;
  }
  .sticky-layout-payment-page {
    position: fixed;
    right: 24px;
    left: 274px;
    top: 62px;
    z-index: 10;
    background: #ffffff;
  }
  .sticky-layout-payment-page ~ .wrap {
    padding-top: 110px;
  }
  .hide-normal {
    display: none;
  }
  .sticky-layout-payment-page .hide-normal {
    display: block;
  }
  #Tournament-Details .table-responsive {
    overflow-x: visible;
  }
  #Tournament-Details .table-responsive.res{overflow-x: auto !important;}
  .tooltip-coordinates tr td:nth-child(3) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(4) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(5) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(6) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(7) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(8) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(9) .player-tooltip,
  .tooltip-coordinates tr td:nth-child(10) .player-tooltip {
    left: -230px;
    margin-left: 0;
    right: initial;
  }
    /*.tooltip-coordinates tr td:nth-last-child(2) .player-tooltip {
    margin-left: -130px;
  }*/
  .tooltip-coordinates tr td:nth-last-child(1) .player-tooltip{    margin-left: -55px;
  }
   /* #breaks tr td:nth-last-child(2) .player-tooltip {
    margin-left: -104px;
}
 .tooltip-coordinates tr td:nth-last-child(2) .player-tooltip{
   margin-left: -108px;
      } */
  #results.tooltip-coordinates tr td:nth-child(3) .player-tooltip {
    left: initial;
    margin-left: 10px;
  }
  .custom-table .action-btn .tool-btn {
    margin: 0 10px;
  }
  /* .add-new-table {
  font-size: 16px;
} */
  .overlay-tooltip::after {
    left: 130px;
  }
  .light-tooltip::after {
    left: 34px;
  }
  .tab-details-view .show-page-number {
    top: -58px;
  }
  .add-club.MemberTransactionsfilter {
    padding: 7px 24px;
  }
  .fixture-share-icon {
    position: absolute;
    right: 30px;
    top: 38px;
  }
  #userDetailsPopup .modal-dialog {
    max-width: 650px;
  }
  .add-table-section .btn-col .btn-gray,
  .add-table-section .btn-red-outline {
    margin-right: 16px;
  }
  .reviewpayment-footer .cancel-btn,
  .reviewpayment-footer .red-btn {
    width: auto;
  }
  .login-page .error-alert {
    top: 135px;
  }
}

@media (min-width: 992px) {
  .w-lg-50 {
    width: 50% !important;
  }
  .sidebar-details {
    padding-left: 270px;
    height: 100%;
  }
  .my-account-sidebar {
    height: 100%;
    z-index: 20;
  }
  .my-account {
    height: 100%;
  }
  .point-of-contact {
    width: 49%;
    margin-left: 14px;
  }
  .profile-details figure img {
    height: 105px;
    width: 105px;
  }
  .bucket-wrapper {
    width: 100%;
  }
  .rrgroups {
    max-width: 100% !important;
  }
  .kkplayers {
    max-width: 330px;
  }
  .bucket-name-wrapper {
    margin-right: 20px;
  }
  #Group0::before,
  .bucket-dropdown::before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    border-right: 1px dashed #cccccc;
    width: 1px;
    top: 0;
  }
  .bucket-name-wrapper-knockout > div {
    width: 33%;
  }
  .bucket-name-wrapper-knockout > div.bucket-placeholder {
    width: auto;
  }
  .bucket-name-wrapper.bucket-name-wrapper-knockout > div:nth-child(1),
  .bucket-name-wrapper.bucket-name-wrapper-knockout > div:nth-child(2),
  .bucket-name-wrapper.bucket-name-wrapper-knockout > div:nth-child(3) {
    margin-top: 0;
  }
  .add-bye-player {
    position: absolute;
    right: 24px;
    top: 88px;
    z-index: 10;
    cursor: pointer;
  }
  .add-club-wrapper .expand-btn {
    right: 20px;
    top: 21px;
  }
  .middle-name {
    clear: none;
    max-width: 500px;
    margin: 0 auto;
  }
  .match-card-item {
    padding: 14px;
  }
  .home-page-footer {
    padding: 28px 0;
  }
  .home-page-footer-nav li {
    padding-left: 40px;
  }
  .home-page-footer-nav li::before {
    left: 18px;
  }

  .main-content {
    padding: 193px 0 0;
  }
  /* .tab-details-view .update-showing .show-page-number {
    top: -28px;
  } */
  .tab-details-view .tab-pane .show-page-number {
    top: -28px;
  }
  .home-page-headeer {
    height: 94px;
  }
  .popup-video .login-page {
    padding: 10px;
  }
  #registerAsPlayer .register-as-player {
    padding: 0;
    background: #eee;
  }
  #registerAsPlayer .add-player-inner {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    padding: 21px;
  }
  .outerBorder {
    max-width: 650px;
  }
  
  .overlay-bg{background: transparent !important;}

  .viewer {
    bottom: 20px;
    margin: 0;
    left: 20px;
  }
  .overlayout {
    padding: 0 200px;margin-top: 1rem;
  }
  /* .scrores{width: 37%;} */
  .overlayout .breaks {
    width: 88%;

}
  .player-tooltip {
    top: 20px;
  }
  #groupDetails tr:nth-last-child(3) td .player-tooltip {
    top: -77px;
}
#groupDetails tr:nth-last-child(2) td .player-tooltip{
  top: -80px;

}
#groupDetails tr:nth-last-child(1) td .player-tooltip {
  top: -124px;
}
#groupDetails tr:nth-last-child(4) td .player-tooltip {
  top: -38px;
}
  .tooltip-coordinates tr:nth-last-child(1) td .player-tooltip,
  .tooltip-coordinates tr:nth-last-child(2) td .player-tooltip,
  .tooltip-coordinates tr:nth-last-child(3) td .player-tooltip,
  .tooltip-coordinates tr:nth-last-child(4) td .player-tooltip {
    top: -160px;
  }
  .custom-table .action-btn .tool-btn {
    margin: 0 15px;
  }
  .overlay-tooltip::after {
    left: 104px;
  }
  .light-tooltip::after {
    left: 0;
  }
  .myTransactionsearch {
    margin-right: 115px;
  }

  .column-wrapper .child:first-child {
    margin-bottom: 0;
    margin-right: 35px;
  }

  .Matches-Schedule-colorCodeText {
    margin-top: 0;
  }
}
@media (min-width: 1050px){
  .datePickerWidth .react-datepicker-wrapper input[type="text"] {
    padding: 10px 15px;
  }
}
@media (min-width: 7680px) {
  .outerBorder {
    max-width: unset !important;
  }.overlay-bg{background: transparent !important;}

  .overLayScore {
    font-size: 180px;

  }
  .playerScores {
    font-size: 180px;
  }
  .playerScoreOne {
    width: 448px;
    margin-right: -102px;
    clip-path: polygon(0 0, 100% 0%, 77% 100%, 0% 100%);padding-right: 34px;
  }
  .playerScoreTwo {
    width: 448px;
    clip-path: polygon(23% 0, 100% 0%, 100% 100%, 0 100%);
    margin-left: -102px;
    padding-left: 34px;

}
  
  .number-score {
    width: 924px;
  }
  .strikeCircle {
    width: 80px;
    height: 80px;
    margin-left: -116px;
    border: 2px solid #fff;

  }
  .overlayout .breaks {
    width: 39%;border-radius: 0px 0px 22px 22px;
  }
  .overlayout .century {
    font-size: 160px;padding: 2px 436px;
    width: auto;

  }
  .overlayout .possible h4, .overlayout .possible h4 span {
    font-size: 160px;line-height: inherit;

  }
  .overlayout .possible h5 span {
    margin: 0px 2px;
  }
  .overlayout .possible h5 {
    font-size: 160px;

  }
  .overlayout .possible h5 span {
    width: 240px;
    height: 240px;
    line-height:240px;

  }
 
}

@media only screen and (min-width: 3600px) and (max-width: 7679px){
  .outerBorder {
    max-width: unset !important;
  }.overlay-bg{background: transparent !important;}
  .overlayout .possible h5 span {
    margin: 0px 2px;
  }
  .overLayScore {
    font-size: 90px;

  }
  .playerScores {
    font-size: 90px;
  }
  .playerScoreOne {
    width: 224px;
    margin-right: -51px;
    clip-path: polygon(0 0, 100% 0%, 77% 100%, 0% 100%);padding-right: 17px;
  }
  .playerScoreTwo {
    width: 224px;
    clip-path: polygon(23% 0, 100% 0%, 100% 100%, 0 100%);
    margin-left: -51px;
    padding-left: 17px;

}
  
  .number-score {
    width: 462px;
  }
  .strikeCircle {
    width: 40px;
    height: 40px;
    margin-left: -59px;
    border: 2px solid #fff;

  }
  .overlayout .breaks {
    width: 40%;border-radius: 0px 0px 22px 22px;
  }
  .overlayout .century {
    font-size: 90px;padding: 2px 187px;
    width: auto;

  }
  .overlayout .possible h4, .overlayout .possible h4 span {
    font-size: 90px;line-height: inherit;

  }
  .overlayout .possible h5 {
    font-size: 90px;

  }
  .overlayout .possible h5 span {
    width: 120px;
    height: 120px;
    line-height: 120px;

  }
 
}

@media only screen and (min-width: 2040px) and (max-width: 3000px) {
  .outerBorder {
    max-width: unset !important;
  }
  .overlayout .possible h5 span {
    margin: 0px 2px;
  }
  .overlay-bg{background: transparent !important;}
  .overlayout .breaks {
    width: 38.3% !important;
    border-radius: 0px 0px 22px 22px;
  }
  .overlayout .breaks.ahead {
    width: 38.3% !important;
  }
  .overLayScore {
    font-size: 45px !important;
  }
  .playerScores {
    font-size: 35px;
  }
  .overlayout .century {
    font-size: 45px;
    padding: 2px 78px;
    width: auto;
  }
  .playerScoreOne {
    width: 115px;
    margin-right: -27px;
    clip-path: polygon(0 0, 100% 0%, 77% 100%, 0% 100%);
    padding-right: 17px;
  }
  .playerScoreTwo {
    width: 115px;
    clip-path: polygon(23% 0, 100% 0%, 100% 100%, 0 100%);
    margin-left: -27px;
    padding-left: 17px;
  }
  
.number-score {
  width: 211px;
}
  .strikeCircle {
    width: 25px;
    height: 25px;
    margin-left: -40px;
    border: 2px solid #fff;

  }
 
  
  .overlayout .possible h4, .overlayout .possible h4 span {
    font-size:45px;line-height: inherit;

  }
  .overlayout .possible h5 {
    font-size: 45px;

  }
  .overlayout .possible h5 span {
    width: 69px;
    height: 69px;
    line-height: 69px;
  }

  .tool-tip-arrow-score::after {
    left: 0 !important;
  }
 
}
@media only screen and (min-width: 1700px) and (max-width: 1920px) {
  .tool-tip-arrow-score::after {
    left: 40px !important;
  }
  .tournament-edit-item .none-select-field {
    width: 100%!important;
}
.tournament-edit-item .col-player-width .none-select-field {
  width: 122px !important;
}
textarea.form-control.round-input {
    width: 100%;
}

.ShowNotPlayer {
  width: 122px;
  max-width: 122px;
}
}
@media only screen and (min-width: 1920px) and (max-width: 2450px) {
  .tournament-edit-item .none-select-field {
    width: 100%!important;
}
.player-col-width {
  margin-left: 102px;
}
.tournament-edit-item .col-player-width .none-select-field {
  width: 200px !important;
}
textarea.form-control.round-input {
    width: 100%;
}
.ShowNotPlayer {
  width: 200px;
  max-width: 200px;
}
}
@media only screen and (min-width: 2450px) and (max-width: 4000px) {
  .tournament-edit-item .none-select-field {
      font-family: "robotoregular";
      pointer-events: auto;
      cursor: pointer;
      width: 100%!important;
  }
  
  textarea.form-control.round-input {
      height: 32px;
      min-height: 32px;
      width: 100%;
  }
  .ShowNotPlayer {
    width: 100%;
    max-width: unset;
}
  .middle-name {
    max-width: 100%;
}
  }
@media only screen and (min-width: 1600px) and (max-width: 2000px) {
  .overlayout .breaks {
    width: 45%;
  }
  .overlayout .breaks.ahead {
    width: 38% !important;
  }
  .overlayout .possible h5 span {
    margin: 0px 1px;
  }
  .overlayout .century {
    padding: 2px 45px;

  }
  .outerBorder {
    max-width: unset !important;
  }
 
  .overlay-bg{background: transparent !important;}
  .overLayScore {
    font-size: 30px;
  }
  .playerScores {
    font-size: 30px;
  }
  .overlayout .century {
    font-size: 30px;
    padding: 2px 69px;
    width: auto;
  }
  .playerScoreOne {
    width: 100px;
    margin-right: -27px;
    clip-path: polygon(0 0, 100% 0%, 77% 100%, 0% 100%);
    padding-right: 17px;
  }
  .playerScoreTwo {
    width: 100px;
    clip-path: polygon(23% 0, 100% 0%, 100% 100%, 0 100%);
    margin-left: -27px;
    padding-left: 17px;
  }
  
  .number-score {
    width: 155px;
  }
  .strikeCircle {
    width: 18px;
    height: 18px;
    margin-left: -30px;
    border: 2px solid #fff;
  }
 
  
  .overlayout .possible h4, .overlayout .possible h4 span {
    font-size:30px;line-height: inherit;

  }
  .overlayout .possible h5 {
    font-size: 30px;

  }
  .overlayout .possible h5 span {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
@media (min-width: 1024px){
  .scrores{width: auto;}
 
} 
@media (min-width: 1200px) {
  .edit-profile {
    overflow: hidden;
    padding-left: 20px;
  }
 
  .player-info-wrapper form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .player-info-wrapper form > .form-group {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 7px;
  }
  /* .player-info-wrapper form > .form-group:nth-child(2n + 1) {
    padding-right: 10px;
  }
  .player-info-wrapper form > .form-group:nth-child(2n + 2) {
    padding-left: 10px;
  } */
  .point-of-contact {
    width: 33%;
  }
  .player-info-wrapper .personal-info {
    padding: 0;
    border: none;
  }
  .player-info-wrapper {
    max-width: 100%;
  }
  /* .updated-photo {
    margin: 0;
  } */
  .manage-player-tab-wrapper > div {
    min-width: 300px;
  }
  .assign-players {
    position: absolute;
    top: -38px;
  }
  .main-content {
    padding: 196px 0 0;
  }
  .match-card-item .match-container:first-child,
  .match-card-item .match-container:nth-child(2) {
    margin-top: 0;
  }
  .match-card-item .match-container {
    flex: 0 0 49.5%;
    max-width: 49.5%;
  }
  .outerBorder {
    max-width: 1170px;
  }
  .overlay-tooltip::after {
    left: 62px;
  }
  .overlay-bg{background: transparent !important;}

  .tournamentCreateButtons .form-group,
  .tournamentCreateButtons .red-btn {
    margin-right: 15px;
  }
  .tournamentCreateHeading {
    padding: 0 0 0 15px;
  }
  .myTransactionsearch {
    margin-right: 0;
  }
  .transaction {
    flex: 0 0 260px !important;
  }
  .calendar-icon {
    top: 9px;
    width: 21px;
  }
  .Matches-Schedule-colorCodeText {
    margin-top: 0;
  }
  .tournament-btn {
    top: 10px !important;
    right: 16px !important;
  }
 
}

@media (max-width: 1325px) {
  .manage-player-tab-wrapper > div {
    min-width: 100px;
  }
}
@media screen and (orientation: landscape) and (max-width: 767px) {
  #root {
    height: initial;
  }
}

@media screen and (max-width: 767px) {
  .sidebar.active ~ .content-wrapper:before, .sidebar.active ~ .add-club-wrapper:before, .sidebar.active ~ .demo-app:before, .sidebar.active ~ .add-player-wrapper:before, .sidebar.active ~ .my-account-wrapper:before{
    opacity:1;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0px;left: 0px;
    background: #46464629;    z-index: 11;
  }
 
    .sidebar.active ~ .add-club-wrapper .tournamentCreateHeading{z-index:unset;}
  .main-container {
    padding: 75px 10px 0px 10px;    height: 100vh;

}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
  .content-wrapper, .add-club-wrapper, .demo-app, .add-player-wrapper, .my-account-wrapper{
    padding: 14px 0px !important;  
    position: inherit;
}

.add-club {
  margin: 0;
  width: auto;
}
}
/* Responsive css end here --------**********-- */

@media screen and (max-width: 778px) {
  .fixture-detail-wrappper {
    display: flex;
  }
  .fixture-champion-container {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 767px) {
  a.btn-expand-collapse {
    display: none;
}
.edit-food .item-content {
  margin-left: 24px;
}
.add-table-section .btn-col .btn-gray, .add-table-section .btn-red-outline {
  margin-right: 10px;
}
.news-details .content-wrapper.white.news {
  padding: 15px !important;
}
}
@media screen and (max-width: 500px) {
  .fixture-card-item .player {
    font-size: 12px;
    line-height: 1.2em;
  }
  .whens {
    position: absolute;
    right: 15px;
    top: 41px;
  }

  .edit-food .item-content {
    margin-left: 0px;
}
  .tournamentCreateHeading{display:block;    text-align: center;
  }
  .tournamentCreateButtons {
    justify-content: center;
    display: flex;
}
  .faq h1 {
    font-size: 26px;
}
.faq .Collapsible span {
  padding: 12px 9px 12px 14px;
  font-size: 17px;
}
.faq .card {
  width: 100%;
  padding: 10px 10px;
}
.faq .Collapsible__contentInner {
  padding: 12px 16px 12px 16px;
}
.faq .card h3 {
  font-size: 17px;
  margin-top: 10px;
}
.videos-page .flex h3 {
  font-size: 17px;
}
.faq .card figure img {
  width: 100%;
  height: 200px;
}
  .faq {
    padding: 0em 0px;
    text-align: left;
    overflow: hidden;
    width: 100%;
    margin: auto;
}
  div#knockout .player-tooltip.large {
    top:unset !important;
  }
  .productmanage .actions-tab {
    min-width: 114px;
    width: 114px;
  }
  .paymentHeight td {
    padding: 7px 10px 5px 8px;
}
  .add-table-section .add-table-forms th {
    padding-left: 0.4em;
    padding-right: 0.4em;
 
}
.custom-table.hourly-table .bx-col .price-col {
    width: 0px;
    padding: 0 1px;
}
.custom-table.hourly-table .bx-col .price-col .price-col {
  width: 27px;
}
.card-details {
  width: 100%;
}
.payment-details {
  flex-basis: calc(100% - 5px);
  max-width: calc(100% - 5px);
}
.stripe-bottom {
  margin-top: 20px;
}
.payment-details .defult-card {
  width: 100%;
  border-left: 1px dotted #000;
  border-right: 1px dotted;
  border-radius: 7px;
}
.custom-table.hourly-table .custom-col-2 {

  padding-right: 0px;
}
  .add-table-section .add-table-forms td {
    padding: 0.6rem 0.4em;
}
.add-table-section .add-table-forms .table.bordernone td {
  padding: 0.3rem 0.4rem;
}
  .allTransaction-list-head .transaction, .allTransaction-list .transaction {
    flex: 0 0 214px !important;
    max-width: 214px;
}
.fc .fc-toolbar-title {
  padding: 10px 0;
}
  .allTransaction-list-head .description, .allTransaction-list .description{
    flex: 0 0 230px !important;
    max-width: 230px;
  }
  .demo-app .searchss .d-flex.schedules {
    display: block !important;
}
a.btn-expand-collapse {
  display: none;
}

  .demo-app .searchss {
    display: block;
}
.paymentMemberTable th, .paymentMemberTable td {
  width: 186px;
  max-width: none;
  flex: unset;
}
.d-flex.upd {
  display: block !important;
}
.demo-app .searchss .pasba-admin {
  padding-left: 0px;
  width: 100% !important;
  padding-bottom: 10px;
}
.fc .fc-toolbar {
  display: block !important;
  text-align: center;
}
.schedule-date-col {
  padding-left: 73px;
  text-align: center;
}
.player-col-width {
  min-width: 293px;
  text-align: center;
  margin-left: 0px;
}
.demo-app.p-4 {
  padding-top: 0px !important;
}
  .fixture-text-bold {
    font-size: 10px;
  }
  .managenews .club-name-bold span {
    width: 132px;
}
  .fixture-best-of {
    font-size: 10px;
  }
  .fixture-player-1 {
    padding-right: 10px;
    min-width: 70%;
  }
  .fixture-player-2 {
    padding-left: 10px;
    min-width: 70%;
  }
  .fixture-player-frames {
    min-width: 50px;
  }
  .fixture-detail-wrappper {
    padding: 10px 15px;
    overflow: auto;
  }
  .fixture-best-of {
    font-size: 10px;
  }
  .best-of-text {
    font-size: 10px;
  }

  .result-search-section div {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .result-search-section div:nth-child(1) {
    padding-right: 6px;
  }
  .result-search-section div:nth-child(2) {
    padding-left: 6px;
  }
  .result-search-section div:last-child {
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 12px;
  }
  .knockout-schedule-colorCodeText {
    margin-top: 46px;
  }
  .koScheduleBtn {
    top: 50px !important;
    right: 83px !important;
  }
}

@media screen and (max-width: 330px) {
.managenews .table {
  width: 141.4% !important;
}
}
@media screen and (max-width: 767px) {
  .live-scoring .live-heading {
    padding: 0 15px;
    margin-top: 20px;
    font-size: 20px;
    line-height: 27px;
  }
  .home-page .no-record {
    margin-top: 3.5em;
    margin-bottom: 2.5em;
}
  .live-scoring .white-col .live-top-head {
    position: relative;
}

.managenews .table th:first-child{
  width: 26%;
}

  .managematchs {
    margin-top: 30px;
}
  .datePickerWidth {
    max-width: calc(100% - 19px);
}
.manage-player-tab.nav-tabs .nav-link {
  font-size: 12px;
  min-width: 142px;
  padding: 12px 17px;
}
.my-account-wrapper {
  padding: 0px 15px;
}
  .live-scoring .match-col-left figure {
    width: 74px;
    height: 74px;
    margin-bottom: 12px;
  }
  .live-scoring .player-center-col .match-col-left .title{width:unset;}

  .inner-container .vs-match {
    padding: 0 8px;
    margin-top: 20px;
  }
  .match-col-left {
    flex-direction: column;
  }
  .match-col-left .left-content {
    order: 2;
  }
  .player-center-col .match-col-left .left-content {
    width: unset;
}
  .match-col-left figure {
    order: 1;
  }
  .player-center-col {
    align-items: flex-start !important;
  }
  .mobile-match-col {
    align-items: flex-end;
    margin-right: 17px;
    margin-top: 4px;
  }
  .mobile-match-col-left {
    align-items: flex-start;
    margin-left: 10px;
    margin-top: 3px;
  }
  .live-scoring .total-matches {
    padding-right: 15px;
  }
  .live-scoring .white-col .live-top-head .live-social-icons {
    font-size: 0px;    z-index: 1111;
  }
  .live-scoring .white-col .inner-container {
    padding-bottom: 40px;min-height:unset;
  }
  .inner-container .match-status span.live-ico {
    top: 12px;
  }
  .match-status .saprater {
    height: 46px;
  }
  .mobile-match-col .total-match-play {
    margin-right: 0px !important;
  }
  .mobile-match-col .total-match-play {
    margin-left: 0 !important;
    order: 1;
  }
  .mobile-match-col-left .title {
    margin-top: 8px;
  }
  .mobile-match-col .title {
    order: 2;
    margin-top: 8px;
  }
  
}
@media (min-width: 768px) {
  .search-section .form-control.type {
    width: 170px;
  }
  .search-section .form-control.game-name {
    width: 170px;
  }
  .search-section .form-control.search {
    width: 322px;
    padding-left: 44px;
  }
  .home-page-headeer {
    height: 89px;
  }
  .search-section {
    align-items: flex-end;
    margin-top: -22px;
  }
  .main-content {
    padding: 110px 0 0;
  }
  .search-section .search-col.tournament-style {
    margin-left: 15px;
  }
  .search-section .search-col.tournament-style,
  .search-section .search-col.sport-type {
    max-width: initial;
    flex: initial;
  }
  .home-page-navigation {
    position: static;
    border: none;
    background: none;
    box-shadow: none;
    width: 750px;
  }
  .home-page-navigation {
    position: static;
    border: none;
    background: none;
    box-shadow: none;
    width: initial;
  }
  .home-page-navigation > li {
    margin-right: 2.2rem;
    margin-top: 0;
  }
  .home-page-navigation > li:last-child {
    margin-right: 0;
  }
  .home-page-navigation {
    padding: 24px 15px 25px;
  }
  .home-page-footer .social {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .logo-cloud {
    margin-left: 0 !important;
    top: -7px;
  }
  .tabbing-header .title-col h2 {
    font-size: 22px;
  }
  .top-head .top-title {
    font-size: 22px;
  }
  .top-head {
    padding: 15px 24px 22px 24px;
  }
  .top-head a {
    font-size: 15px;
    padding: 0 16px;
  }
  .tabbing-header .nav-tabs .nav-item {
    margin-right: 16px;
  }
  .team-content .heading {
    padding: 17px 24px 14px 24px;
  }
  .knockout-container {
    padding: 0 24px 14px 24px;
  }
  .group-heading {
    padding: 0 24px 9px 24px;
  }
  .top-head a span {
    margin-right: 11px;
  }
  .result-search-section div,
  .result-search-section div:last-child {
    width: 152px;
    margin-top: 0;
    margin-left: 13px;
    flex: initial;
  }
  .result-search-section {
    margin-top: 0;
  }
  .date-col {
    padding: 0 24px 4px 24px;
  }
  .result-search-section div:nth-child(1) {
    padding-right: 0;
  }
  .result-search-section div:nth-child(2) {
    padding-left: 0;
  }

  .logo {
    max-width: 99px;
    position: relative;
    z-index: 2;
  }
  .logo-cloud {
    margin-left: 30px;
  }
  .search-section .search-col.tournament-style,
  .search-section .search-col.sport-type {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
 
}
@media screen and (min-width: 992px) {
  .search-section .search-col {
    margin-left: 20px;
  }
  .home-page-footer .social {
    top: 39px;
    transform: translateY(-50%);
    right: 20px;
  }
  .top-section h2 {
    top: 15px;
    position: relative;
  }
  .highest-break {
    font-size: 18px;
    line-height: 20px;
  }
  .food-beverage .item-col {
    max-width: calc(33.333333% - 7px);
    flex: 0 0 calc(33.333333% - 7px);
  }
  .food-beverage .item-col:nth-child(3n-1) {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 1200px) {
  .allTransactionTopLayout.sticky-layout{    min-width: auto;}

  .allTransactionTopLayout {
    min-width: 1000px;
  }
div#tournamentSchedule .group-tab {
  width: 100%;
}

}
@media (max-width:1024px) {
.inner-container .match-status {
  max-width: 100%;
}

.create-tournament-red-btn {
  padding: 10px 19px;
}
.accordion>.card.layout-view {
  overflow-x: hidden;
}
.player-info-wrapper .edit-profile {
  padding-left: 0px;
  overflow: revert;
}
.add-club-management .organization-information {
  width: 100%;
}
.myTransactionsearch{margin-bottom:10px;}
.food-beverage .item-col .item-title-col .title{
  width: 50px;
}
.overlayout .breaks {
  width: 33.3%;
}
.overlayout .breaks.ahead {
  width: 28.6% !important;
}
.wrap .list {
  min-width: 1000px;
}
.tooltip-on-hover:hover + .tooltip-cvv {
  left: 86%;
  width: fit-content;
  top: 22px;
  z-index: unset;
}

.column-wrapper .child:first-child{    margin-right: 35px;
}
.groupnit .bucket-wrapper.kkplayers {
  flex: 0 0 310px;
  margin-right: 36px;
}
.groupnit ul.nav.nav-tabs.d-flex {
  width: auto !important;
}
.groupnit .group-tab {
  justify-content: space-between;
}
.whens.knockout-whens {
  margin-top: 45px;
}
}
@media (max-width: 840px){
.create-tournament-red-btn {
    font-size: 13.5px;
    padding: 10px;
}
.wrap.transactionloader .react-datepicker-popper[data-placement^="top"]{
  transform: translate3d(0px, 48px, 0px) !important;
}
.wrap.transactionloader .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  bottom: unset !important;
  margin-bottom: 0px !important;
  top: 0px !important;
  margin-top: -7px !important;
  transform: rotate(181deg) !important;
}
}
@media screen and (max-width: 768px) {
  .groupnit{overflow-x: scroll;}
  .whens.knockout-whens {
    margin-top: 43px;
}
.red-btn {
  font-size: 14px;
  padding: 10px 22px;
}
.allTransactionTopLayout.sticky-layout {
  display: block;
  position: unset;
  min-width: 1000px;
  animation: unset;
}
}
@media screen and (max-width: 767px) {
  .food-beverage .item-col .item-title-col .title {
    width: 121px;
}


.groupnit ul.nav.nav-tabs.d-flex {
  width: 550px !important;
}
.tournament-btn {
  top: 40px !important;
  right: 9px !important;
}

.email-condition label {
  display: none;
}
.email-condition .btn.btn-link {
  margin-top:0px;
  padding: 0px;
}
.email-condition .alert-success {
  padding: 7.4px;
  margin-top:0px;
}
.email-condition .spinner-border {
  margin-top: 0px;
}

.bucket-wrapper .tooltiptext {
  bottom: 27px;
  left: -226px;
}
.upcomingtourna .box .btn{display: flex;}
.upcomingtour select#selectClub {
  margin-bottom: 5px;
}
.content-wrapper.white.news .backs {
  margin-left: -93px;
  margin-top: -12px;
}
span.tooltiptext.toolwhen {
  left: -209px;
  bottom: -44px;
}
.match-player-schedule .lefts, .match-player-schedule .rights {
  width: 55%;
}
.login-page.enters .popup-close-btn {
  right: 8px;
  top: 8px;
}
.Matches-Schedule-colorCodeText{
  margin-top: 0px;
}
.knockout-schedule-colorCodeText {
  margin-top:0px;
}
.whens.knockout-whens {
  margin-top:42px;
}

.login-page.enters .error-alert {
  top: 0%;
}
.result-search-section .form-control {
  padding: 5px 14px;
}
.result-search-section svg {
  right: 2px;
}
.result-search-section div, .result-search-section div:last-child {
  margin-left: 7px;
}
.tab-menu-bar svg:last-child {
  transform: translate(0px,-3px);
}
.view-twich-channel .dropdown-menu.show {
  right: -129px;
}
.tooltip-bucket-wapper {
  right: 31px;
}

.tab-menu-bar.active svg#nitbars {
  display: none;
}
.tooltip-bucket::before {
  content: "";
  top: -2px;
  right: -29px;
  left: unset;
  transform: rotate(180deg);
}
.sidebar {  z-index:1101;    top: 73px;    padding: 8px 0 0 0;
}
.nitOverLay{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 1;display: none;}

.tab-menu-bar.active .nitOverLay{display: block;}
.tab-menu-bar.active.nitActive .nitOverLay{display: none;}
.menutops{border-top: 1px solid #bfbbbb;padding:0.5em 0 0px;margin-top:0.5em;display: block;}
.add-club-wrapper .details-match.details .col-lg-6.col-md-6{padding-right:15px;}
.content-wrapper.wrappers{padding:5px 10px !important;}
}
@media screen and (max-width: 600px) {
  .groupnit ul.nav.nav-tabs.d-flex {
    width: 484px !important;
}
.koScheduleBtn {
  top: 40px!important;
  right: 9px!important;
}
}
@media (min-width: 1200px) {
  .search-section .search-col.tournament-style,
  .search-section .search-col.sport-type {
    flex-direction: row;
    align-items: center;
  }

  .top-section h2 {
    position: static;
    font-size: 28px;
  }
  .home-page-headeer {
    height: 94px;
    padding: 0 20px;
  }
  .main-content {
    padding: 126px 0 0;
  }
  .logo {
    max-width: 137px;
  }
  /*.home-page-navigation {
    width: 750px;
  }*/
  .search-section .form-control.game-name {
    width: 214px;
  }
  .card-container .col-xl-3 {
    padding-right: 6px;
    padding-left: 6px;
  }
  .live-match-col .col-xl-6 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .search-section {
    padding: 0 6px;
  }
  .home-page-footer:after {
    content: "";
    background: url(../images/bg-right-bottom.png) left top no-repeat;
    position: absolute;
    bottom: 20px;
    right: 0;
    height: 498px;
    width: 575px;
    z-index: 1;
  }
  .top-head {
    padding: 15px 32px 22px 32px;
    z-index: 5;
    position: relative;
  }
  .tabbing-header {
    padding: 19px 32px 0 32px;
  }
  .tabbing-header .nav-tabs .nav-item {
    margin-right: 32px;
  }
}
@media screen and (max-width: 500px) {
  span.tooltiptext.toolwhen {
    left: -230px;
    bottom: 19px;
}

.Matches-Schedule-colorCodeText{
  margin-top: 28px;
}
.knockout-schedule-colorCodeText {
  margin-top: 28px;
}

}
@media screen and (max-width: 450px) {
.groupnit ul.nav.nav-tabs.d-flex {
  width: 244px !important;
}
.allTransactionTopLayout .form-inline{max-width:unset;}
}
@media (max-width: 500px) {
.result-search-section svg {
  right: 14px;
}
.result-search-section div, .result-search-section div:last-child {
  margin-left: 0px;
}
  td.match-player-schedule .player-schedule.d-flex.mt-2.align-items-center {
  display: block !important;
}
.match-player-schedule .groups {
  width: 100%;
}
.match-player-schedule .detailss {
  justify-content: center;
  width: 100%;
}
.match-player-schedule .lefts, .match-player-schedule .rights {
  width: 100%;
}
.match-player-schedule .player-title {
  font-size: 16px;
}
}