.internet-connection{
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 10000000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.internet-connection > div{
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    padding: 20px;
    font-size: 16px;;
}