/*
npx json-server --watch db.json --port 3004 

font-family: 'robotoregular';
font-family: 'robotoitalic';
font-family: 'robotomedium';
font-family: 'robotomedium_italic';
font-family: 'robotobold';
font-family: 'robotobold_italic';
font-family: 'robotothin';
font-family: 'robotothin_italic';
font-family: 'robotolight';
font-family: 'robotolight_italic';
font-family: 'robotoblack';
font-family: 'robotoblack_italic';
font-family: 'roboto_condensedregular';

*/

/* Font face css start here */

@font-face {
  font-family: "robotoregular";
  src: url("assets/fonts/roboto-regular.woff2") format("woff2"),
    url("assets/fonts/roboto-regular.woff") format("woff"),
    url("assets/fonts/roboto-regular.ttf") format("ttf");
}
input:-internal-autofill-selected{-webkit-box-shadow: 0 0 0 30px white inset !important;background-color: transparent !important;}
.form-control:-internal-autofill-selected{-webkit-box-shadow: 0 0 0 30px white inset !important;background-color: transparent !important;}

@font-face {
  font-family: "robotoitalic";
  src: url("assets/fonts/roboto-italic.woff2") format("woff2"),
    url("assets/fonts/roboto-italic.woff") format("woff"),
    url("assets/fonts/roboto-italic.ttf") format("ttf");
}

@font-face {
  font-family: "robotomedium";
  src: url("assets/fonts/roboto-medium.woff2") format("woff2"),
    url("assets/fonts/roboto-medium.woff") format("woff"),
    url("assets/fonts/roboto-medium.ttf") format("ttf");
}

@font-face {
  font-family: "robotomedium_italic";
  src: url("assets/fonts/roboto-mediumitalic.woff2") format("woff2"),
    url("assets/fonts/roboto-mediumitalic.woff") format("woff"),
    url("assets/fonts/roboto-mediumitalic.ttf") format("ttf");
}

@font-face {
  font-family: "robotobold";
  src: url("assets/fonts/roboto-bold.woff2") format("woff2"),
    url("assets/fonts/roboto-bold.woff") format("woff"),
    url("assets/fonts/roboto-bold.ttf") format("ttf");
}

@font-face {
  font-family: "robotobold_italic";
  src: url("assets/fonts/roboto-bolditalic.woff2") format("woff2"),
    url("assets/fonts/roboto-bolditalic.woff") format("woff"),
    url("assets/fonts/roboto-bolditalic.ttf") format("ttf");
}

@font-face {
  font-family: "robotothin";
  src: url("assets/fonts/roboto-thin.woff2") format("woff2"),
    url("assets/fonts/roboto-thin.woff") format("woff"),
    url("assets/fonts/roboto-thin.ttf") format("ttf");
}

@font-face {
  font-family: "robotothin_italic";
  src: url("assets/fonts/roboto-thinitalic.woff2") format("woff2"),
    url("assets/fonts/roboto-thinitalic.woff") format("woff"),
    url("assets/fonts/roboto-thinitalic.ttf") format("ttf");
}

@font-face {
  font-family: "robotolight";
  src: url("assets/fonts/roboto-light.woff2") format("woff2"),
    url("assets/fonts/roboto-light.woff") format("woff"),
    url("assets/fonts/roboto-light.ttf") format("ttf");
}

@font-face {
  font-family: "robotolight_italic";
  src: url("assets/fonts/roboto-lightitalic.woff2") format("woff2"),
    url("assets/fonts/roboto-lightitalic.woff") format("woff"),
    url("assets/fonts/roboto-lightitalic.ttf") format("ttf");
}

@font-face {
  font-family: "robotoblack";
  src: url("assets/fonts/roboto-black.woff2") format("woff2"),
    url("assets/fonts/roboto-black.woff") format("woff"),
    url("assets/fonts/roboto-black.ttf") format("ttf");
}

@font-face {
  font-family: "robotoblack_italic";
  src: url("assets/fonts/roboto-blackitalic.woff2") format("woff2"),
    url("assets/fonts/roboto-blackitalic.woff") format("woff"),
    url("assets/fonts/roboto-blackitalic.ttf") format("ttf");
}

@font-face {
  font-family: 'roboto_condensedregular';
  src: url('assets/fonts/robotocondensed-regular.woff2') format('woff2'),
    url('assets/fonts/robotocondensed-regular.woff') format('woff');
}

.robotomedium {
  font-family: 'robotomedium';
}

/* Font face css end here ------------------**********-- */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "robotoregular";
  /*background-color: #eeeeee;*/
  background: linear-gradient(0deg, #f5fcff 0%, #fdf6f9 100%) fixed !important;    height: 100vh;

}

code {
  font-family: "robotoregular";
}

html {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder,
input::-ms-input-placeholder {
  font-size: 16px;
  line-height: 19px;
  color: #999999;  font-family: "Roboto", sans-serif;
}
.search-box::placeholder,
.search-box::-webkit-input-placeholder,
.search-box::-moz-placeholder,
.search-box::-ms-input-placeholder {
  font-size: 13.7px;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

a:hover,
a:active {
  color: inherit;
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "robotobold";
}

ul {
  list-style: none;
}

select {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
}

select::-ms-expand {
  display: none;
}

h3 {
  font-size: 22px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 12px;
}

h4 {
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

strong {
  font-weight: 400;
  font-family: 'robotobold';
}

/* Radio btn css start here --------------------------- */

.radio-btn {
  position: relative;
}

.radio-btn label {
  padding: 5px 0 5px 26px;
  z-index: 1;
  position: relative;
}

.radio-checked,
.radio-unchecked {
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 1;
}

.radio-btn input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.radio-btn input~.radio-checked,
.radio-btn input:checked~.radio-unchecked {
  display: none;
}

.radio-btn input:checked~.radio-checked,
.radio-btn input~.radio-unchecked {
  display: block;
}


/* Radio btn css end here -----------------**********-- */
button:focus,
.btn:focus,
.btn.focus {
  box-shadow: none;
  outline: none;
}

/* Checkbox css start here ---------------------------- */
.checkmark {
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 1px solid #999999;
  display: block;
}

.checkmark.error{
  border: 1px solid #b52830;
  box-shadow: 0px 0px 3px -1px #b52830;
}

input:checked~.checkmark:after {
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  display: block;
  margin: auto;
}

input:checked~.checkmark {
  background-color: #124e25;
  border: 3px solid #124e25;
}

.checkbox-element {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
}


/* Checkbox css end here ------------------**********-- */

.text-error {
  color: #b52830;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  top: 41px;
  left: 0;
}

.modal-content {
  padding: 30px 16px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.popup-close-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  line-height: 0;
  z-index: 5;
}

.border-none {
  border: none !important;
}

.modal label {
  font-size: 15px;
  line-height: 18px;
  color: #333333;
  font-family: "robotomedium";
}

.modal .form-control.textarea-col {
  height: 104px;
}
.modal .form-control {
  border-radius: 3px;
  border: solid 1px #cccccc;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  height: 40px;
}

.modal select.form-control {
  background:none;
  position: relative;
  color: #333333;
}
.modal select.form-control:hover{cursor: pointer;}
.red-btn {
  font-size: 16px;
  line-height: 19px;
  color: #ffffff !important;
  font-family: "robotomedium";
  border-radius: 20px;
  background-color: #b52830;
  min-width: 100px;
  height: 40px;
  border: 1px solid #f07279;
  margin: 5px;
  padding: 10px 28px;
}

.red-btn:hover, .delete-iframe-modal .btn-red:hover, .edit-food .modal-footer .btn-red:hover {
  box-shadow: none;
  color: #ffffff;
  background-color: #8b1118;
  border-color: white;
}
.red-btn:focus, .delete-iframe-modal .btn-red:focus, .edit-food .modal-footer .btn-red:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 111, 0.5) !important;
}
.delete-iframe-modal .btn-gray:hover, .edit-food .modal-footer .btn-gray:hover, .share-iframe-modal .modal-footer .btn-gray:hover{ color: #333333;
  background-color: #d9d5d5;}
.delete-iframe-modal .btn-gray:focus, .edit-food .modal-footer .btn-gray:focus, .share-iframe-modal .modal-footer .btn-gray:focus{box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 50%) !important;
}
.add-club:hover {
  box-shadow: none;
  color: #ffffff;
  background-color: #8b1118;
}
.add-club:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 111, 0.5) !important;
}


.slot-btn-outline{
  border: 1.5px solid #b52830;
  color: #b52830;
  border-radius: 19px;
  padding-left: 29px;
  padding-right: 29px;
}

/* .cancel-btn {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  font-family: "robotomedium";
  border-radius: 20px;
  background-color: #ebebeb;
  min-width: 100px;
  height: 40px;
  border: none;
  margin: 0;
}
.cancel-btn:hover {
  box-shadow: none;
  color: #000000;
  background-color: #ebebeb;
} */
.back-btn {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  font-family: "robotomedium";
  border-radius: 20px;
  background-color: #ffffff;
  min-width: 100px;
  height: 40px;
  border: none;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn:hover {
  box-shadow: none;
  color: #000000;
  background-color: #ffffff;
}

.cancel-btn {
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  font-family: "robotomedium";
  border-radius: 20px;
  background-color: #ebebeb;
  min-width: 100px;
  height: 40px;
  border: none;
  margin: 0;
}

.cancel-btn:hover {
  box-shadow: none;
  color: #333333;
  background-color: #d9d5d5;
}
.cancel-btn:focus{
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important;
}

.expand-btn {
  font-size: 16px;
  line-height: 19px;
  color: #b52830;
  font-family: "robotomedium";
  background-color: #ffffff;
  min-width: 100px;
  height: 40px;
  border: none;
  margin: 0;
  padding: 10px 28px;
  border-radius: 20px;
  border: solid 1px #b52830;
  cursor: pointer;
}

.button-randomize {
  position: absolute !important;
  top: -58px !important;
  right: 0px !important;
}

.expand-btn:hover {
  box-shadow: none;
  color: #b52830;
  background-color: #ffffff;
}

.cursor-pointer {
  cursor: pointer;
}
.sortKey:hover{
  background-color: #cfd1d0;
}
.wrap.transactionloader{position: relative;overflow: unset !important;}
.transactionloader .loader{    border: 9px solid #f3f3f3;
  border-top: 9px solid #3498db;
  width: 50px;
  height: 50px;
  left: -129px;
  top: 25px;
  z-index: 1111;}
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  bottom: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pointer-event {
  pointer-events: none;
}

.flex-1 {
  flex: 1;
}

.star {
  font-size: 15px;
  line-height: 18px;
  color: #b52830;
  top: 0;
}

.form-control {
  height: 40px;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 10px 12px;
}

label {
  font-size: 15px;
  line-height: 18px;
  color: #333333;
  font-family: "robotomedium";
}

.dashed-border,
.modal .form-control.dashed-border {
  border: 1px dashed #999999;
}

.input-error,
.modal .form-control.input-error {
  border: 1px solid #b52830;
}

.input-error-dashed {
  border: 1px dashed #b52830;
}

.error-alert {
  color: #b52830;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  font-family: "robotomedium";
}

.success-alert {
  color: #155724;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  font-family: "robotomedium";
}

.switch input {
  display: none;
}

.switch {
  display: inline-block;
  width: 51px;
  height: 24px;
  margin: 0;
  position: relative;
}
.inner-wrap{min-width: 1000px}
.mobile-screen-Transaction {
  font-size: 14px;
  padding: 14px 20px 17px 20px;
  min-width: 1000px;
  border-radius: 0px;
  background: #fff;
}
.wrap .list {
  padding: 14px 20px 7px 20px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
  border-radius: 0px;
  background: #fff;
}
.wrap h5{
width: 100%;
}
.wrap .list .total_curr {
  width: fit-content;
}
.wrap .list .total_curr strong {
  font-size: 10px;
  color: #bcbcbc;
  font-weight: 100;
}

.slider-switch {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
}

.slider-switch:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  border-radius: 30px;
  transform: translateX(-26px);
  transition: 0.4s;
}

input:checked~.slider-switch:before {
  transform: translateX(26px);
  background: #f1f1f1;
}

input~.slider-switch {
  background: #999999;
}

input:checked~.slider-switch {
  background: #124e25;
}
.bucket_slider input:checked~.slider-switch {
  background: #b52830;
}

img {
  max-width: 100%;
  height: auto;
}

.display-table {
  display: table;
  width: 100%;
  height: 100%;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.red-text-btn,
.red-text-btn:hover {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  color: #b52830;
  font-family: 'robotomedium';
}

/* Loader css start here -------------------------------- */

svg {
  display: block;
  margin: 0;
  padding: 0;
}

.spinner {
  width: 66px;
  height: 66px;
  animation: contanim 2s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.spinner svg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: rotate(-90deg);
}

.spinner svg:nth-child(1) circle {
  stroke: #84ebbd;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(.2s * (1)) ease infinite;
  transform-origin: center center;
}

.spinner svg:nth-child(2) circle {
  stroke: #4977ec;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(.2s * (2)) ease infinite;
  transform-origin: center center;
}

.spinner svg:nth-child(3) circle {
  stroke: #f6bb67;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(.2s * (3)) ease infinite;
  transform-origin: center center;
}

.spinner svg:nth-child(4) circle {
  stroke: #124e25;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(.2s * (4)) ease infinite;
  transform-origin: center center;
}

@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.54832;
  }

  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.64497;
  }
}

@keyframes contanim {
  100% {
    transform: rotate(360deg);
  }
}

select.form-control:hover{cursor: pointer;}
/* Loader css end here ----------------------**********-- */
select.form-control:focus {
  box-shadow: none;
}
.form-control:disabled, .form-control[readonly]{cursor:unset !important;}

.disable-edit-delete {
  pointer-events: none;
  opacity: 0.5;
}

/* .text-truncate{ */
/* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
/* } */
/* Responsive css start here ------------------ */

@media screen and (min-width: 375px) {}

@media screen and (min-width: 576px) {}

@media (min-width: 768px) {
  .modal-content {
    padding: 32px;
  }
}

@media (min-width: 992px) {}

@media (max-width: 4000px) and (min-width:3000px){
.container {
    max-width: 3765px !important;
}
}
@media (min-width: 1200px) {
  .container {
    max-width: 1290px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (orientation: landscape) and (max-width: 767px) {}

/* Responsive css end here --------**********-- */
.social-icons {
  position: absolute;
  top: -12px;
  width: 65px;
  right: 0;
  z-index: 1;
}

@media screen and (max-width : 764px) {
  .social-icons {
    position: absolute;
    top: -36px;
    width: 65px;
    right: -4px;
    z-index: 1;
  }
}

.relative {
  position: relative;
}

.koScheduleBtn {
  top: 10px !important;
  right: 10px !important;
}